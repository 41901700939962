import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Banner, LegacyCard, Frame, Layout, Loading, Page, Spinner, LegacyStack, Text, Tooltip } from '@shopify/polaris';
import { DataPoint, DataSeries, LineChart, PolarisVizProvider } from '@shopify/polaris-viz';

import { TopBarMarkup, NavigationMarkup, OrganizationAnalyticsDatePicker } from '../../../components';

import './Analytics.scss';
import { parseDateLabelChart } from '../../../utils/Common';

export function OrganizationAnalytics({ user }: any) {
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [error, setError] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive);
  }, []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive(true);
  };

  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Analytics
   */
  const [premioNetto, setPremioNetto] = useState(-1);
  const [premioLordo, setPremioLordo] = useState(-1);
  const [provvTot, setProvvTot] = useState(-1);
  const [customers, setCustomers] = useState([]);

  const [products, setProducts] = useState([]);

  // Loading
  useEffect(() => {
    if (provvTot !== -1 && premioLordo !== -1 && premioNetto !== -1) {
      setIsLoading(false);
    }
  }, [provvTot, premioLordo, premioNetto]);

  // Pass function to DashboardDatePicker
  const handleAnalytics = (data: any) => {
    setPremioNetto(data.premioNetto);
    setPremioLordo(data.premioLordo);
    setProvvTot(data.provvTot);
    setCustomers(data.customers);
    setProducts(data.products);
  };

  /**
   * Banner markups
   */
  const bannerMarkup = error && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  /**
   * Charts
   */
  /** Products */
  const productsData: DataPoint[] = [];
  const productsDataMap = new Map<string, number | undefined>();
  products.forEach((product: any) => {
    const date_start = parseDateLabelChart(new Date(product.date_start));
    if (productsDataMap.has(date_start)) {
      let tmp = productsDataMap.get(date_start);
      // @ts-ignore
      tmp += 1;
      productsDataMap.set(date_start, tmp);
    } else {
      productsDataMap.set(date_start, 1);
    }
  });

  // Convert productsDataMap to productsData
  productsDataMap.forEach((value: number | undefined, key: string) => {
    productsData.push({
      key: key,
      value: value || null,
    });
  });

  const productsDataSerie: DataSeries[] = [
    {
      data: productsData.reverse(),
      color: '#f0672f',
      name: 'Prodotti',
    },
  ];

  const productsChart = (
    <LegacyStack vertical>
      <Text variant="headingMd" as="h2">
        Evoluzione nel tempo
      </Text>
      <LineChart data={productsDataSerie} showLegend={false} />
    </LegacyStack>
  );

  /** Premio netto & lordo & commission */
  const premioNettoData: DataPoint[] = [];
  const premioLordoData: DataPoint[] = [];
  const commissionData: DataPoint[] = [];
  const premioNettoDataMap = new Map<string, number | undefined>();
  const premioLordoDataMap = new Map<string, number | undefined>();
  const commisionDataMap = new Map<string, number | undefined>();
  products.forEach((product: any) => {
    const date_start = parseDateLabelChart(new Date(product.date_start));
    // Premio netto
    if (premioNettoDataMap.has(date_start)) {
      let tmp = premioNettoDataMap.get(date_start);
      // @ts-ignore
      tmp += product.premio_netto;
      premioNettoDataMap.set(date_start, tmp);
    } else {
      premioNettoDataMap.set(date_start, product.premio_netto);
    }
    // Premio lordo
    if (premioLordoDataMap.has(date_start)) {
      let tmp = premioLordoDataMap.get(date_start);
      // @ts-ignore
      tmp += product.premio_lordo;
      premioLordoDataMap.set(date_start, tmp);
    } else {
      premioLordoDataMap.set(date_start, product.premio_lordo);
    }
    // Commission
    if (commisionDataMap.has(date_start)) {
      let tmp = commisionDataMap.get(date_start);
      // @ts-ignore
      tmp += (product.provvtotali / 100) * product.premio_netto;
      commisionDataMap.set(date_start, tmp);
    } else {
      commisionDataMap.set(date_start, (product.provvtotali / 100) * product.premio_netto);
    }
  });

  // Convert premioNettoDataMap to premioNettoData
  premioNettoDataMap.forEach((value: number | undefined, key: string) => {
    premioNettoData.push({
      key: key,
      value: value || null,
    });
  });
  // Convert premioLordoDataMap to premioLordoData
  premioLordoDataMap.forEach((value: number | undefined, key: string) => {
    premioLordoData.push({
      key: key,
      value: value || null,
    });
  });
  // Convert commisionDataMap to commissionData
  commisionDataMap.forEach((value: number | undefined, key: string) => {
    commissionData.push({
      key: key,
      value: value || null,
    });
  });

  const premioNettoDataSerie: DataSeries[] = [
    {
      data: premioNettoData.reverse(),
      color: '#f0672f',
      name: 'Premio netto',
    },
  ];
  const premioLordoDataSerie: DataSeries[] = [
    {
      data: premioLordoData.reverse(),
      color: '#f0672f',
      name: 'Premio lordo',
    },
  ];
  const commissionDataSerie: DataSeries[] = [
    {
      data: commissionData.reverse(),
      color: '#f0672f',
      name: 'Provvigioni',
    },
  ];

  const premioNettoChart = (
    <LegacyStack vertical>
      <Text variant="headingMd" as="h2">
        Evoluzione nel tempo
      </Text>
      <LineChart data={premioNettoDataSerie} showLegend={false} />
    </LegacyStack>
  );
  const premioLordoChart = (
    <LegacyStack vertical>
      <Text variant="headingMd" as="h2">
        Evoluzione nel tempo
      </Text>
      <LineChart data={premioLordoDataSerie} showLegend={false} />
    </LegacyStack>
  );
  const commissionChart = (
    <LegacyStack vertical>
      <Text variant="headingMd" as="h2">
        Evoluzione nel tempo
      </Text>
      <LineChart data={commissionDataSerie} showLegend={false} />
    </LegacyStack>
  );

  /** Customers */
  const customersData: DataPoint[] = [];
  const customersDataMap = new Map<string, number | undefined>();
  customers.forEach((customer: any) => {
    const date_created = parseDateLabelChart(new Date(customer.date_created));
    if (customersDataMap.has(date_created)) {
      let tmp = customersDataMap.get(date_created);
      // @ts-ignore
      tmp += 1;
      customersDataMap.set(date_created, tmp);
    } else {
      customersDataMap.set(date_created, 1);
    }
  });

  // Convert customersDataMap to customersData
  customersDataMap.forEach((value: number | undefined, key: string) => {
    customersData.push({
      key: key,
      value: value || null,
    });
  });

  const customersDataSerie: DataSeries[] = [
    {
      data: customersData.reverse(),
      color: '#f0672f',
      name: 'Clienti',
    },
  ];

  const customersChart = (
    <LegacyStack vertical>
      <Text variant="headingMd" as="h2">
        Evoluzione nel tempo
      </Text>
      <LineChart data={customersDataSerie} showLegend={false} emptyStateText="Nessun cliente" />
    </LegacyStack>
  );

  /**
   * Page markup
   */
  const pageMarkup = (
    <Page fullWidth title="Dashboard">
      {/* Banners */}
      {bannerMarkup}
      <div className="dashboardHeader">
        <LegacyStack alignment="center">
          <OrganizationAnalyticsDatePicker handleAnalytics={handleAnalytics} userId={user.id} />
          <Text as="span" color="subdued">
            Usato per la visualizzazione dei dati
          </Text>
        </LegacyStack>
      </div>
      <div className="dashboardContent">
        {/* First col */}
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            {/* Polizze emesse */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Polizze calcolate a partire dalla data impostata" dismissOnMouseOut preferredPosition="above">
                  <Text variant="headingMd" as="h2">
                    <span className="cardTitle">Polizze emesse</span>
                  </Text>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <Text variant="heading2xl" as="p">
                    {products.length}
                  </Text>
                )}
                {productsChart}
              </LegacyStack>
            </LegacyCard>
            {/* Active customers */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Numero di clienti attivi" dismissOnMouseOut preferredPosition="above">
                  <Text variant="headingMd" as="h2">
                    <span className="cardTitle">Clienti</span>
                  </Text>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <Text variant="heading2xl" as="p">
                    {Number(customers.length)}
                  </Text>
                )}
                {customersChart}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
        {/* Second col */}
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            {/* Premio netto */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Premio netto calcolato a partire dalla data impostata" dismissOnMouseOut preferredPosition="above">
                  <Text variant="headingMd" as="h2">
                    <span className="cardTitle">Premio netto</span>
                  </Text>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <Text variant="heading2xl" as="p">
                    {Number(premioNetto).toFixed(2)} €
                  </Text>
                )}
                {premioNettoChart}
              </LegacyStack>
            </LegacyCard>
            {/* Commission */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Provvigioni calcolate a partire dalla data impostata" dismissOnMouseOut preferredPosition="above">
                  <Text variant="headingMd" as="h2">
                    <span className="cardTitle">Provvigioni</span>
                  </Text>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <Text variant="heading2xl" as="p">
                    {Number(provvTot).toFixed(2)} €
                  </Text>
                )}
                {commissionChart}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
        {/* Third col */}
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            {/* Premio lordo */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Premio lordo calcolato a partire dalla data impostata" dismissOnMouseOut preferredPosition="above">
                  <Text variant="headingMd" as="h2">
                    <span className="cardTitle">Premio lordo</span>
                  </Text>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <Text variant="heading2xl" as="p">
                    {Number(premioLordo).toFixed(2)} €
                  </Text>
                )}
                {premioLordoChart}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
      </div>
    </Page>
  );

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      <PolarisVizProvider
        themes={{
          Default: {
            chartContainer: {
              backgroundColor: '#fff',
              padding: '0.5rem 0',
            },
            grid: {
              horizontalMargin: 0,
              horizontalOverflow: false,
              // showVerticalLines: true,
              color: '#bfbfbf',
            },
            yAxis: {
              backgroundColor: '#fff',
              labelColor: '#8c8c8c',
            },
            xAxis: {
              labelColor: '#8c8c8c',
            },
            line: {
              hasSpline: true,
              hasArea: true,
            },
          },
        }}
      >
        {loadingMarkup}
        {pageMarkup}
      </PolarisVizProvider>
    </Frame>
  );
}
