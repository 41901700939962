import React, { useCallback, useRef, useState } from 'react';

import {
  Banner,
  ContextualSaveBar,
  FormLayout,
  Frame,
  Layout,
  Loading,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TextField,
  Toast,
  LegacyCard,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup, contextControlMarkup } from '../../../../components';

import './UserNew.scss';
import { UserRoles } from '../../../../types';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../../utils/PrivateRoute';
import axios, { AxiosError } from 'axios';

type CreateUserType = {
  name: string;
  lastname: string;
  email: string;
  role: string;
  type?: 'private' | 'company';
};

export function UserNew() {
  const navigate = useNavigate();
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(true);
  const [error, setError] = useState(false);
  const [exist, setExist] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const [defaultState, setDefaultState] = useState({
    name: '',
    lastname: '',
    email: '',
    role: '',
  });
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [type, setType] = useState<'private' | 'company'>('private');

  const [missingFields, setMissingFields] = useState({
    name: false,
    email: false,
  });

  // Role options
  const options = [];
  const userRole = user && user.role;
  const [role, setRole] = useState(userRole === 'admin' ? 'admin' : 'manager');
  if (userRole === 'admin') {
    options.push(
      { label: 'Admin', value: UserRoles.admin },
      { label: 'Manager', value: UserRoles.manager },
      { label: 'Analyst', value: UserRoles.analyst },
      { label: 'Sales', value: UserRoles.sales },
      { label: 'Partner', value: UserRoles.partner },
    );
  } else {
    options.push(
      { label: 'Manager', value: UserRoles.manager },
      { label: 'Analyst', value: UserRoles.analyst },
      { label: 'Sales', value: UserRoles.sales },
      { label: 'Partner', value: UserRoles.partner },
    );
  }

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  /** Discard */
  const handleDiscard = useCallback(() => {
    setName(defaultState.name);
    setLastname(defaultState.lastname);
    setEmail(defaultState.email);
    setIsDirty(false);
  }, [defaultState]);

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);

      let error_flag = false;

      if (!name) {
        setMissingFields((fields) => ({ ...fields, name: true }));
        error_flag = true;
      }

      if (!email) {
        setMissingFields((fields) => ({ ...fields, email: true }));
        error_flag = true;
      }

      if (error_flag) return;

      const axios_body: CreateUserType = {
        name: name,
        lastname: lastname ? lastname : '',
        email: email,
        role: role,
      };

      if (role === 'customer') {
        axios_body.type = type;
      }

      const response = await axios.post((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/admin/users/new`, axios_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;

      if (data.status === 'success') {
        setIsDirty(false);
        setActive(true);
        setButtonSpinning(false);
        setTimeout(() => {
          navigate(`/admin/users/${data.data}`);
        }, 3000);
      }
    } catch (error) {
      const axios_error = error as AxiosError;
      const status = axios_error.response?.status || 500;

      if (status === 409) {
        setExist(true);
      } else {
        setError(true);
      }
    } finally {
      setButtonSpinning(false);
    }
  }, [name, lastname, email, role, type]);

  /** Handler */
  const handleNameChange = useCallback(
    (e: string) => {
      if (missingFields.name) setMissingFields((fields) => ({ ...fields, name: false }));

      setName(e);
      setIsDirty(true);
    },
    [missingFields.name],
  );

  const handleLastnameChange = useCallback((e: string) => {
    setLastname(e);
    setIsDirty(true);
  }, []);

  const handleEmailChange = useCallback(
    (e: string) => {
      if (missingFields.email) setMissingFields((fields) => ({ ...fields, email: false }));

      setEmail(e);
      setIsDirty(true);
    },
    [missingFields.email],
  );

  const handleSelectChange = useCallback((value: any) => setRole(value), []);
  const handleTypeChange = useCallback((value: 'private' | 'company') => {
    setType(value);
  }, []);

  const loadingMarkup = isLoading ? <Loading /> : null;

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Utente non salvato"
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
      contextControl={contextControlMarkup}
    />
  ) : null;

  /**
   * Markups & error
   */
  const saveError = error && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const existError = exist && (
    <Layout.Section>
      <Banner title="Esiste già un utente associato a questo indirizzo email" status="critical" onDismiss={() => setExist(false)}>
        <p>Si è pregati di cambiare l'email utente se si desidera proseguire.</p>
      </Banner>
    </Layout.Section>
  );

  const toastMarkup = active ? <Toast content="L'account è stato creato con successo." onDismiss={toggleActive} /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <Page title={`Nuovo utente`} backAction={{ content: 'Utenti', url: '/admin/users' }}>
      <Layout>
        {/* Banner */}
        {saveError}
        {existError}
        {/* Panoramica account */}
        <Layout.AnnotatedSection title="Dettagli account">
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="text"
                  label="Nome"
                  value={name}
                  onChange={handleNameChange}
                  error={missingFields.name && 'Il nome è obbligatorio'}
                />
                <TextField autoComplete="on" type="text" label="Cognome" value={lastname} onChange={handleLastnameChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="email"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                  helpText="Verrà utilizzato per l'accesso all'account."
                  error={missingFields.email && "L'email è obbligatoria"}
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Ruolo account">
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Select label="Ruolo" options={options} onChange={handleSelectChange} value={role} helpText="In base al ruolo l'utente avrà viste diverse." />
                {role === 'customer' && (
                  <Select
                    label="Tipologia cliente"
                    options={[
                      { label: 'Freelance', value: 'private' },
                      { label: 'Small Business', value: 'company' },
                    ]}
                    onChange={handleTypeChange}
                    value={type}
                  />
                )}
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {contextualSaveBarMarkup}
      {pageMarkup}
      {toastMarkup}
    </Frame>
  );
}
