import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { LegacyCard, LegacyStack, Spinner, Text } from '@shopify/polaris';

interface Props {
  user: any;
}

export const ReportsCards = ({ user }: Props) => {
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [reportsAvaible, setReportsAvaible] = useState(0);
  const [familyReports, setFamilyReports] = useState(0);
  const [individualReports, setIndividualReports] = useState(0);
  //const [totalReports, setTotalReports] = useState(0);
  /**
   * Fetch Wallet
   */
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/admin/organizations/${user.organization}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          },
        );
        const data = response.data;

        if (data.status === 'success') {
          const { wallet } = data.data.organization;
          setReportsAvaible(wallet.family_analysis);
        }

        const { reports_family, reports_individual } = data.data;
        setFamilyReports(reports_family);
        setIndividualReports(reports_individual);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrganizations();
  }, []);

  const totalReports = familyReports + individualReports;

  const cardsMarkup = (
    <div className="dashboardContent">
      {/* First col */}
      <div className="dashboardContentCol">
        <div className="dashboardContentColPadding">
          <LegacyCard
            title="Reports disponibili"
            sectioned
            actions={[
              {
                content: 'Acquista altri reports',
                url: '/organization/reports/buy',
              },
            ]}
          >
            <LegacyStack vertical>
              <Text variant="heading2xl" as="p">
                {loading ? <Spinner size="small" /> : reportsAvaible}
              </Text>
            </LegacyStack>
          </LegacyCard>
        </div>
      </div>
      {/* First col */}
      <div className="dashboardContentCol">
        <div className="dashboardContentColPadding">
          <LegacyCard sectioned title="Report fatti">
            <LegacyStack vertical>
              <Text variant="heading2xl" as="p">
                {loading ? <Spinner size="small" /> : totalReports}
              </Text>
            </LegacyStack>
          </LegacyCard>
        </div>
      </div>
    </div>
  );
  return cardsMarkup;
};
