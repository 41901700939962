import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Avatar,
  Banner,
  LegacyCard,
  ContextualSaveBar,
  DropZone,
  EmptyState,
  Filters,
  FormLayout,
  Frame,
  Layout,
  Modal,
  Page,
  ResourceItem,
  ResourceList,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  LegacyStack,
  TextContainer,
  TextField,
  Text,
  Thumbnail,
  Toast,
  Badge,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../../components';

import { getInitials } from '../../../utils/Common';
import axios, { AxiosError } from 'axios';
import { OrganizationRoles, User } from '../../../types';
import { NoteMinor } from '@shopify/polaris-icons';
import { useUser } from '../../../utils/PrivateRoute';
import { useParams } from 'react-router-dom';

export function OrganizationSettings() {
  const { user } = useUser();
  const params = useParams();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [update, setUpdate] = useState(false);
  const [active, setActive] = useState(false);
  const [modalRoleActive, setModalRoleActive] = useState(false);
  const [modalRemoveLogoActive, setModalRemoveLogoActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [removeLogoSpinning, setRemoveLogoSpinning] = useState(false);
  const [error, setError] = useState(false);
  const [pwdError, setPwdError] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const [items, setItems] = useState<{ user: User; role: string }[]>([]);
  const [frontItems, setFrontItems] = useState<{ user: User; role: string }[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [taggedWith, setTaggedWith] = useState('VIP');
  const [queryValue, setQueryValue] = useState('');
  const [sortValue, setSortValue] = useState('NAME_DESC');

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  /**
   * States
   */
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [vat, setVat] = useState('');
  const [fiscalData, setFiscalData] = useState('');
  const [orderSequentialName, setOrderSequentialName] = useState('');

  const [line, setLine] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('IT'); // Value is country.code
  const [province, setProvince] = useState('');
  const [reports, setReports] = useState(0);
  const [reportTypes, setReportTypes] = useState<{ reports_family: number; reports_individual: number }>({ reports_family: 0, reports_individual: 0 });
  const [familyReports, setFamilyReports] = useState(0);
  const [familyUnlimited, setFamilyUnlimited] = useState(false);
  const [individualReports, setIndividualReports] = useState(0);
  const [totalReports, setTotalReports] = useState(0);

  const [memberId, setMemberId] = useState('');
  const [users, setUsers] = useState<{ user: string; role: string }[]>([]);

  // Logo
  const [logo, setLogo] = useState<File>();
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  // Default
  const [defaultState, setDefaultState] = useState({
    name: '',
    email: '',
    phone: '',
    vat: '',
    fiscalData: '',
    orderSequentialName: '',
    line: '',
    city: '',
    zip: '',
    country: 'IT',
    province: '',
    logo: {
      key: '',
      title: '',
    },
    reports: '',
    familyUnlimited: false,
  });

  /**
   * Role
   */
  const [selectedRole, setSelectedRole] = useState<string>(OrganizationRoles.user);
  const handleSelectRoleChange = useCallback((value: any) => setSelectedRole(value), []);

  const roleOptions = [
    { label: 'Admin', value: OrganizationRoles.admin },
    { label: 'User', value: OrganizationRoles.user },
  ];

  /** Discard */
  const handleDiscard = useCallback(() => {
    setName(defaultState.name);
    setEmail(defaultState.email);
    setPhone(defaultState.phone);
    setVat(defaultState.vat);
    setFiscalData(defaultState.fiscalData);
    setLine(defaultState.line);
    setCity(defaultState.city);
    setZip(defaultState.zip);
    setCountry(defaultState.country);
    setProvince(defaultState.province);
    setLogo(undefined);
    setIsDirty(false);
  }, [defaultState]);

  /**
   * Data fetching:
   * - fetch organization
   * - fetch users
   */
  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/organization/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const organization = data.data.organization;
          setDefaultState({
            name: organization.name,
            email: organization.email,
            phone: organization.phone,
            vat: organization.vat,
            fiscalData: organization.fiscal_data,
            orderSequentialName: organization.order_sequential_name,
            line: organization.address.line,
            city: organization.address.city,
            zip: organization.address.zip,
            country: organization.address.country,
            province: organization.address.province,
            logo: {
              key: organization.logo.key,
              title: organization.logo.title,
            },
            reports: organization.wallet.family_analysis,
            familyUnlimited: organization.wallet.family_unlimited,
          });
          setName(organization.name);
          setEmail(organization.email);
          setPhone(organization.phone);
          setVat(organization.vat);
          setFiscalData(organization.fiscal_data);
          setOrderSequentialName(organization.order_sequential_name);
          setLine(organization.address.line);
          setCity(organization.address.city);
          setZip(organization.address.zip);
          setCountry(organization.address.country);
          setProvince(organization.address.province);
          setReports(organization.wallet.family_analysis);
          setFamilyUnlimited(organization.wallet.family_unlimited);

          setItems(organization.users);
          setFrontItems(organization.users);

          const tmp = [];
          for (const user of organization.users) {
            tmp.push({ user: user.user._id, role: user.role });
          }
          setUsers(tmp);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrganization();
  }, [params.id, update]);

  /**
   * Add user to organization
   */
  const handleAddUser = useCallback(
    async (userId: any, role: any) => {
      try {
        setButtonSpinning(true);
        const response = await axios.post(
          (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/organizations/${params.id}`,
          {
            user: userId,
            role: role,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          },
        );
        const data = response.data;

        if (data.status === 'success') {
          setActive(true);
        } else {
          setError(true);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        handleDiscard();
        setError(true);
      } finally {
        setButtonSpinning(false);
      }
    },
    [handleDiscard, params.id],
  );

  /**
   * Resource list
   */
  const handleSelectionChange = useCallback((selectedItems: any) => setSelectedItems(selectedItems), []);

  const handleTaggedWithRemove = useCallback(() => setTaggedWith(''), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTaggedWithRemove]);

  const resourceName = {
    singular: 'utente',
    plural: 'utenti',
  };

  const filters: any[] = [];

  // Filtering
  useEffect(() => {
    const filterItems = () => {
      const filteredItems = items.filter((item: { user: User }) => {
        return user.name.toLowerCase().includes(queryValue.toLowerCase());
      });
      setFrontItems(filteredItems);
    };
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  const handleQueryChange = useCallback((e: string) => {
    setQueryValue(e);
  }, []);

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={filters}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleClearAll}
    ></Filters>
  );

  /**
   * Render row
   */
  function renderItem(item: any, _: any, index: number | undefined) {
    const { user: partner, role }: { user: User; role: string } = item;
    const media = <Avatar customer={false} size="medium" initials={`${getInitials(partner.name)}`} name={'_id'} />;

    return (
      <ResourceItem
        id={partner._id}
        onClick={() => void 0}
        media={media}
        sortOrder={index}
        accessibilityLabel={`View details for ${name}`}
        shortcutActions={[
          {
            content: 'Modifica ruolo',
            onAction: () => {
              setSelectedRole(role);
              setMemberId(partner._id);
              handleModalRoleChange();
            },
          },
        ]}
      >
        <div className="dataScroll">
          <div>
            <Text as="span" fontWeight="semibold">
              {partner.name} {partner._id === user.id && '(Io)'}
            </Text>
          </div>
          <div>
            <p>
              <Text as="span" color="subdued">
                Email: {partner.email} - Ruolo: {String(role).charAt(0).toUpperCase() + String(role).slice(1)}
              </Text>
            </p>
          </div>
        </div>
      </ResourceItem>
    );
  }

  function resolveItemIds({ id }: any) {
    return id;
  }

  /** Empty list markup */
  const emptyListMarkup = !items.length && (
    <EmptyState heading="Gestisci i membri" image="https://cdn.shopify.com/shopifycloud/web/assets/v1/e7b58a8b2e612fe6cf6f8c9e53830b70.svg">
      <p>Qua è dove puoi gestire le informazioni dei tuoi membri</p>
    </EmptyState>
  );

  /**
   * Remove members
   */
  const handleRemoveMembers = useCallback(async () => {
    try {
      const response = await axios.delete(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/organizations/${params.id}/users`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
          data: {
            users: selectedItems,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        // Remove users from frontItems
        const newFrontItems = frontItems.filter((item) => {
          return !selectedItems.includes(item.user._id);
        });
        setFrontItems(newFrontItems);
        setActive(true);
      } else {
        setError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setError(true);
    }
  }, [frontItems, params.id, selectedItems]);

  const promotedBulkActions = [
    {
      content: 'Rimuovi utenti',
      onAction: handleRemoveMembers,
    },
  ];

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);

      // Create form data
      const formData = new FormData();
      if (logo) {
        formData.append('document', logo, logo?.name);
      }
      formData.append('name', name);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('vat', vat);
      formData.append('fiscal_data', fiscalData);
      formData.append('line', line);
      formData.append('city', city);
      formData.append('zip', zip);

      // Send request
      const response = await axios.put((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/organization/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;

      if (data.status === 'success') {
        setActive(true);
        setIsDirty(false);
        setUpdate(!update);
      } else {
        handleDiscard();
        setError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      handleDiscard();
      setError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [name, email, phone, vat, fiscalData, line, city, zip, logo, handleDiscard, update]);

  /**
   * Remove logo
   */
  const handleModalRemoveLogoChange = useCallback(() => {
    setModalRemoveLogoActive(!modalRemoveLogoActive);
  }, [modalRemoveLogoActive]);

  const handleRemoveLogo = useCallback(async () => {
    try {
      setRemoveLogoSpinning(true);
      const organization = user.role === 'admin' ? params.id : user.organization;

      const response = await axios.delete(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/organizations/${organization}/logo`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setLogo(undefined);
        setActive(true);
      } else {
        setError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setError(true);
    } finally {
      setRemoveLogoSpinning(false);
      handleModalRemoveLogoChange();
    }
  }, [user.organization, handleModalRemoveLogoChange]);

  /**
   * Change role in organization
   */
  const handleModalRoleChange = useCallback(async () => {
    setModalRoleActive(!modalRoleActive);
  }, [modalRoleActive]);

  const handleUpdateRole = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.put(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/organizations/${params.id}/role`,
        {
          user: memberId,
          role: selectedRole,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        // Update user role in frontItems
        const newItems = [...frontItems];
        const index = newItems.findIndex((item) => item.user._id === memberId);
        if (index !== -1) {
          newItems[index].role = selectedRole;
        }
        setFrontItems(newItems);

        setActive(true);
        handleModalRoleChange();
      } else {
        setError(true);
        handleModalRoleChange();
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      handleDiscard();
      handleModalRoleChange();
      setError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [frontItems, handleDiscard, handleModalRoleChange, params.id, memberId, selectedRole]);

  /**
   * Handlers
   */
  const handleNameChange = useCallback((e: string) => {
    setName(e);
    setIsDirty(true);
  }, []);

  // Email
  const handleEmailChange = useCallback((e: string) => {
    setEmail(e);
    setIsDirty(true);
  }, []);

  // Vat
  const handleVatChange = useCallback((e: string) => {
    setVat(e);
    setIsDirty(true);
  }, []);

  // Fiscal data
  const handleFiscalDataChange = useCallback((e: string) => {
    setFiscalData(e);
    setIsDirty(true);
  }, []);

  // Order sequential name
  const handleOrderSequentialNameChange = useCallback((e: string) => {
    setOrderSequentialName(e);
    setIsDirty(true);
  }, []);

  // Phone
  const handlePhoneChange = useCallback((e: string) => {
    // Allow only numbers
    if (e.match(/^[0-9]*$/)) {
      setPhone(e);
    }
    setIsDirty(true);
  }, []);

  // Line
  const handleLineChange = useCallback((e: string) => {
    setLine(e);
  }, []);

  // City
  const handleCityChange = useCallback((e: string) => {
    setCity(e);
  }, []);

  // Zip
  const handleZipChange = useCallback((e: string) => {
    // Allow only numbers
    if (e.match(/^[0-9]*$/)) {
      setZip(e);
    }

    // Allow only 5 numbers
    if (e.length > 5) {
      setZip(e.substring(0, 5));
    }
  }, []);

  // Country
  const handleCountryChange = useCallback((e: any) => {
    setCountry(e);
  }, []);

  /**
   * Organization drop zone logo
   */
  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
    setLogo(acceptedFiles[0]);
    setIsDirty(true);
  }, []);

  const fileInDb = (
    <div style={{ padding: '0' }}>
      <LegacyStack alignment="center">
        <Thumbnail size="large" alt={defaultState.logo.title} source={process.env.REACT_APP_BLOB_IMAGES_URL + defaultState.logo.key} />
        <div>{defaultState.logo.title}</div>
      </LegacyStack>
    </div>
  );

  const fileUpload = !logo && defaultState.logo.key ? fileInDb : !logo ? <DropZone.FileUpload /> : null;

  const uploadedFile = logo && (
    <div style={{ padding: '0' }}>
      <LegacyStack alignment="center">
        <Thumbnail size="large" alt={logo.name} source={validImageTypes.includes(logo.type) ? window.URL.createObjectURL(logo) : (NoteMinor as any)} />
        <div>{logo.name}</div>
      </LegacyStack>
    </div>
  );

  const logoDropZoneMarkup = (
    <DropZone onDrop={handleDropZoneDrop} allowMultiple={false}>
      {uploadedFile}
      {fileUpload}
    </DropZone>
  );

  /**
   * Save bar
   */
  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Modifiche non salvate"
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
    />
  ) : null;

  /**
   * Banner
   */
  const bannerMarkup = error && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const bannerWrongPwdMarkup = pwdError && (
    <Layout.Section>
      <Banner title="Password errata" status="critical" onDismiss={() => setPwdError(false)}>
        <p>Si è pregati di ricontrollare la password.</p>
      </Banner>
    </Layout.Section>
  );

  /** Modal to change user role */
  const modalRoleMarkup = (
    <Modal
      open={modalRoleActive}
      onClose={handleModalRoleChange}
      title="Aggiorna ruolo"
      primaryAction={{
        content: 'Continua',
        onAction: handleUpdateRole,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handleModalRoleChange,
        },
      ]}
    >
      <Modal.Section>
        <Select label="Ruolo" options={roleOptions} onChange={handleSelectRoleChange} value={selectedRole} />
      </Modal.Section>
    </Modal>
  );

  /**
   * Remove logo modal
   */
  const removeLogoAction = defaultState.logo.key
    ? [
        {
          content: 'Rimuovi logo',
          onAction: handleModalRemoveLogoChange,
        },
      ]
    : undefined;

  const modalRemoveLogoMarkup = (
    <Modal
      open={modalRemoveLogoActive}
      onClose={handleModalRemoveLogoChange}
      title="Rimuovi logo"
      primaryAction={{
        content: 'Continua',
        onAction: handleRemoveLogo,
        loading: removeLogoSpinning,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handleModalRemoveLogoChange,
        },
      ]}
    >
      <Modal.Section>
        <p>Si è sicuri di voler procedere con la rimozione?</p>
        <p>Una volta eliminato non è più possibile tornare indietro.</p>
      </Modal.Section>
    </Modal>
  );

  /**
   * Organization logo title
   */
  const organizationLogoTitle = (
    <Avatar
      customer={false}
      size="medium"
      source={process.env.REACT_APP_BLOB_IMAGES_URL ? `${process.env.REACT_APP_BLOB_IMAGES_URL}${defaultState.logo.key}` : ''}
      name={'_id'}
    />
  );

  /**
   * Checks if family reports are unlimited
   */
  const unlimitedReports = familyUnlimited === true ? 'Illimitati' : 'Limitati';

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page title={`${defaultState.name}`} titleMetadata={organizationLogoTitle}>
      <Layout>
        {/* Banner */}
        {bannerMarkup}
        {bannerWrongPwdMarkup}
        {/* Total Reports */}
        <Layout.AnnotatedSection title="Reports" description="Numero di reports disponibili e di reports effettuati.">
          <LegacyCard sectioned>
            <LegacyStack vertical>
              <Text variant="headingMd" as="h2">
                <span className="cardTitle">Report totali</span>
                <span> </span>
                <Badge>{unlimitedReports}</Badge>
              </Text>
              <Text variant="heading2xl" as="p">
                {familyUnlimited ? <>&#8734;</> : reports}
              </Text>
            </LegacyStack>
          </LegacyCard>
          <LegacyCard sectioned>
            <LegacyStack vertical>
              <Text variant="headingMd" as="h2">
                <span className="cardTitle">Report effettuati</span>
              </Text>
              <Text variant="heading2xl" as="p">
                {totalReports}
              </Text>
              <Text variant="headingMd" as="p">
                Family reports: {familyReports}
              </Text>
              <Text variant="headingMd" as="p">
                Individual reports: {individualReports}
              </Text>
            </LegacyStack>
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Panoramica organizazzione */}
        <Layout.AnnotatedSection
          title="Dettagli organizzazione"
          description="Un'organizzazione rappresenta un partner (broker, agenzia, azienda, altro) che ha un accordo di collaborazione."
        >
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="Nome" value={name} onChange={handleNameChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="P.IVA" value={vat} onChange={handleVatChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="email"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                  helpText="Verrà utilizzato per le comunicazioni all'azienda."
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="tel"
                  label="Numero di telefono"
                  value={phone}
                  onChange={handlePhoneChange}
                  helpText="Verrà utilizzato per le comunicazioni all'azienda."
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>

        {/* Fiscal Data */}
        <Layout.AnnotatedSection
          title="Dati fiscali"
          description="Le informazioni fiscali che dovranno essere stampate nei preventivi e nelle fatture delle polizze collettive."
        >
          <LegacyCard sectioned>
            <TextField label="Dati fiscali" value={fiscalData} onChange={handleFiscalDataChange} multiline={4} autoComplete="off" />
          </LegacyCard>
        </Layout.AnnotatedSection>

        {/* Logo */}
        <Layout.AnnotatedSection title="Logo" description="Il logo dell'organizzazione. Caricare una nuova immagine per sostituire quella attuale.">
          <LegacyCard sectioned actions={removeLogoAction}>
            {logoDropZoneMarkup}
          </LegacyCard>
        </Layout.AnnotatedSection>

        {/* Order sequential name */}
        <Layout.AnnotatedSection
          title="Progressivo ordini"
          description="Il codice progressivo, relativo all'organizzazione, che sarà utilizzato per identificare i preventivi e le fatture."
        >
          <LegacyCard sectioned>
            <TextField label="Progressivo" value={orderSequentialName} onChange={handleOrderSequentialNameChange} autoComplete="off" disabled />
          </LegacyCard>
        </Layout.AnnotatedSection>

        {/* Address */}
        <Layout.AnnotatedSection title="Indirizzo" description="L'indirizzo principale di questa organizzazione.">
          <LegacyCard sectioned>
            <FormLayout>
              {/* <FormLayout.Group>
                <Select label="Stato" options={countryOptions} onChange={handleCountryChange} value={country} />
              </FormLayout.Group> */}
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="Indirizzo" value={line} onChange={handleLineChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="CAP" value={zip} onChange={handleZipChange} />
                <TextField autoComplete="on" type="text" label="Città" value={city} onChange={handleCityChange} />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Organization users */}
        <Layout.AnnotatedSection title="Membri organizzazione">
          <LegacyCard>
            <ResourceList
              resourceName={resourceName}
              items={frontItems}
              renderItem={renderItem}
              emptyState={emptyListMarkup}
              selectedItems={selectedItems}
              onSelectionChange={handleSelectionChange}
              resolveItemId={resolveItemIds}
              filterControl={filterControl}
              sortValue={sortValue}
              sortOptions={[
                { label: 'Data aggiunta utente (dal più recente)', value: 'DATE_CREATED_DESC' },
                { label: 'Data aggiunta utente (dal meno recente)', value: 'DATE_CREATED_ASC' },
              ]}
              onSortChange={(selected) => {
                setSortValue(selected);
              }}
              loading={isLoading}
              promotedBulkActions={promotedBulkActions}
            />
          </LegacyCard>
        </Layout.AnnotatedSection>
        <Layout.Section />
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  /**
   * Toast markup
   */
  const toastMarkup = active ? <Toast content="I dati sono stati aggiornati" onDismiss={toggleActive} /> : null;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {pageMarkup}
      {modalRoleMarkup}
      {modalRemoveLogoMarkup}
      {toastMarkup}
    </Frame>
  );
}
