import React, { useCallback, useState } from 'react';
import {
  CustomersMajor,
  HomeMajor,
  ProductsMajor,
  SettingsMajor,
  NoteMajor,
  FraudProtectUnprotectedMajor,
  AnalyticsMajor,
  BankMajor,
  OrdersMajor,
  ReportsMajor,
  StoreStatusMajor,
  InventoryMajor,
  CirclePlusMajor,
  CartMajor,
} from '@shopify/polaris-icons';

import { Navigation, SubNavigationItem } from '@shopify/polaris';
import { navigationContextControlMarkup } from '../NavigationContextControlMarkup';

const BookIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path d="M6.75 6a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5Z" />
    <path d="M6.75 9a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5Z" />
    <path d="M6.75 12a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5Z" />
    <path d="M12 6.75a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75Z" />
    <path d="M12 9.75a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75Z" />
    <path
      fillRule="evenodd"
      d="M7.586 3.5c.87 0 1.714.273 2.414.771a4.164 4.164 0 0 1 2.414-.771h2.336c.966 0 1.75.784 1.75 1.75v9.5a1.75 1.75 0 0 1-1.75 1.75h-.238a9.25 9.25 0 0 0-3.161.557l-1.095.398a.75.75 0 0 1-.493.007l-1.46-.487a9.25 9.25 0 0 0-2.926-.475h-.127a1.75 1.75 0 0 1-1.75-1.75v-9.5c0-.966.784-1.75 1.75-1.75h2.336Zm-2.586 1.75a.25.25 0 0 1 .25-.25h2.336c.608 0 1.194.208 1.664.584v10.125l-.473-.157a10.75 10.75 0 0 0-3.4-.552h-.127a.25.25 0 0 1-.25-.25v-9.5Zm5.75 10.43v-10.096a2.664 2.664 0 0 1 1.664-.584h2.336a.25.25 0 0 1 .25.25v9.5a.25.25 0 0 1-.25.25h-.238a10.75 10.75 0 0 0-3.674.647l-.088.032Z"
    />
  </svg>
);

export function NavigationMarkup({ user }: any) {
  const [navItemActive, setNavItemActive] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsLoading = useCallback(() => setIsLoading((isLoading) => !isLoading), []);

  const location = window.location.pathname;

  /**
   * Products sub navigation
   */
  const productsSubNavigationMarkup: SubNavigationItem[] = [
    {
      label: 'Tutti i prodotti',
      url: '/admin/products',
      onClick: () => {
        toggleIsLoading();
        setNavItemActive('products');
      },
    },
  ];
  if (user.role === 'admin') {
    productsSubNavigationMarkup.push({
      label: 'Categorie',
      url: '/admin/products/categories',
      onClick: () => {
        toggleIsLoading();
        setNavItemActive('products');
      },
    });
  }

  /**
   * Reports sub navigation
   */
  const reportsSubNavigationMarkup: SubNavigationItem[] = [
    {
      label: 'Retail e Famiglie',
      url: '/reports/family',
      onClick: () => {
        toggleIsLoading();
        setNavItemActive('reports');
      },
    },
    {
      label: 'Business (beta)',
      url: '/reports',
      onClick: () => {
        toggleIsLoading();
        setNavItemActive('reports');
      },
    },
  ];

  // ---- Navigation ----
  const navigationMarkup = (
    <Navigation location={location} contextControl={navigationContextControlMarkup}>
      <Navigation.Section
        items={[
          {
            label: 'Home',
            icon: HomeMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('home');
            },
            url: '/',
            exactMatch: true,
          },
          {
            label: 'Clienti',
            icon: CustomersMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('customers');
            },
            url: '/customers',
          },
          {
            label: 'Analisi Assicurativa',
            icon: StoreStatusMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('reports');
            },
            url: '/reports',
            secondaryActions: [
              {
                accessibilityLabel: 'Altri',
                icon: CirclePlusMajor as any,
              },
            ],
            subNavigationItems: reportsSubNavigationMarkup,
          },
          {
            label: 'Prodotti',
            icon: ProductsMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('questionnaires');
            },
            url: '/questionnaires',
          },
          {
            label: 'Preventivi',
            icon: NoteMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('quotes');
            },
            url: '/quotes',
          },
          {
            label: 'Polizze',
            icon: OrdersMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('policies');
            },
            url: '/policies',
          },
          {
            label: 'Sinistri',
            icon: FraudProtectUnprotectedMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('claims');
            },
            url: '/claims',
          },
          {
            label: 'Guide',
            icon: BookIcon,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('guide');
            },
            url: '/guide',
          },
          // {
          //   label: 'Analisi',
          //   icon: AnalyticsMajor,
          //   onClick: () => {
          //     toggleIsLoading();
          //     setNavItemActive('analytics');
          //   },
          //   url: '/analytics',
          // },
        ]}
      />
      {user?.organization && (
        <Navigation.Section
          title="Organizzazione"
          items={[
            {
              label: 'Analytics',
              icon: AnalyticsMajor as any,
              onClick: () => {
                toggleIsLoading();
              },
              url: '/organization',
              exactMatch: true,
            },
            {
              label: 'Impostazioni',
              icon: SettingsMajor as any,
              onClick: () => {
                toggleIsLoading();
              },
              url: '/organization/settings',
              exactMatch: true,
            },
            {
              label: 'Acquista report',
              icon: CartMajor as any,
              onClick: () => {
                toggleIsLoading();
              },
              url: '/organization/reports/buy',
              exactMatch: true,
            },
          ]}
        />
      )}
      {user && user.role === 'admin' && (
        <Navigation.Section
          title="Admin"
          items={[
            {
              label: 'Utenti',
              icon: CustomersMajor as any,
              onClick: () => {
                toggleIsLoading();
                setNavItemActive('users');
              },
              url: '/admin/users',
            },
            {
              label: 'Fabbrica prodotti',
              icon: ProductsMajor as any,
              onClick: () => {
                toggleIsLoading();
                setNavItemActive('products');
              },
              secondaryAction: {
                accessibilityLabel: 'Altri',
                icon: CirclePlusMajor as any,
              },
              url: '/admin/products',
              subNavigationItems: productsSubNavigationMarkup,
            },
            {
              label: 'Intermediari',
              icon: BankMajor as any,
              onClick: () => {
                toggleIsLoading();
                setNavItemActive('organizations');
              },
              url: '/admin/organizations',
            },
            {
              label: 'Reports personalizzati',
              icon: ReportsMajor as any,
              onClick: () => {
                toggleIsLoading();
                setNavItemActive('reports');
              },
              url: '/admin/reports',
            },
            {
              label: 'Compagnie',
              icon: InventoryMajor,
              onClick: () => {
                toggleIsLoading();
                setNavItemActive('companies');
              },
              url: '/admin/companies',
            },
          ]}
        />
      )}
      <Navigation.Section fill items={[]} />
      <Navigation.Section
        items={[
          {
            label: 'Impostazioni',
            icon: SettingsMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('settings');
            },
            url: '/settings',
            matchPaths: ['/settings', '/settings/*'],
          },
        ]}
      />
    </Navigation>
  );
  return navigationMarkup;
}
