import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Button, Combobox, FormLayout, Icon, LegacyStack, Modal, Select, Tag, TextContainer, TextField } from '@shopify/polaris';

import { OrganizationProduct, Report } from '../../../../../types';
import { SearchMinor } from '@shopify/polaris-icons';
import axios, { AxiosError } from 'axios';

type AutocompleteType = {
  value: string;
  label: string;
};

export function CommissionModal(
  modalStatus: boolean,
  setModalStatus: any,
  orgProduct: OrganizationProduct | null,
  setOrgProduct: any,
  isNew: boolean,
  saveOrgProduct: any,
  removeOrgProduct: any,
) {
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  /**
   * DiscountApplication states
   */
  const valueOptions = useMemo(
    () => [
      { label: 'Importo', value: 'fixed_amount' },
      { label: 'Percentuale', value: 'percentage' },
    ],
    [],
  );

  /**
   * Reports states
   */
  const [deselectedOptions, setDeselectedOptions] = useState<AutocompleteType[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<AutocompleteType[]>([]);
  const [loading, setLoading] = useState(false);
  const [reportId, setReportId] = useState<string | null>(null);
  const [reportName, setReportName] = useState<string | null>(null);
  const [reports, setReports] = useState<{ _id: string; name: string }[]>([]);

  // Empty fields
  const [emptyFields, setEmptyFields] = useState({
    value: false,
  });

  /**
   * Fetch reports
   */
  useEffect(() => {
    const fetchReports = async () => {
      try {
        setLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/reports`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });

        const data = response.data;
        if (data.status === 'success') {
          const tmp: AutocompleteType[] = [];
          data.data.forEach((report: Report) => {
            tmp.push({ value: report._id, label: report.name });
          });
          setDeselectedOptions(tmp);
          setOptions(tmp);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          console.log(axiosError.response.data);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchReports();
  }, []);

  /**
   * Handlers
   */
  const handleValueChange = useCallback(
    (value: any) => {
      if (emptyFields.value) {
        setEmptyFields({ ...emptyFields, value: false });
      }

      // Check if value is a number
      if (isNaN(value)) {
        return;
      }

      // Block user from deleting the first value
      if (value === '') {
        return;
      }

      setOrgProduct({ ...orgProduct, value: parseFloat(value) });
    },
    [emptyFields.value, orgProduct],
  );
  const handleValueTypeChange = useCallback((value: any) => setOrgProduct({ ...orgProduct, value_type: value }), [orgProduct]);

  /**
   * Autocomplete handlers
   */
  const updateText = useCallback(
    (value: any) => {
      // if (orgProduct?.report && orgProduct?.report.name) {
      //   setIsEditing(true);
      // }
      if (orgProduct?.reports) {
        setIsEditing(true);
      }
      setInputValue(value);

      if (!loading) {
        setLoading(true);
      }

      if (value === '') {
        setOptions(deselectedOptions);
        setReportId(null);
        setLoading(false);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedOptions.filter((option: AutocompleteType) => option.label.match(filterRegex));
      setOptions(resultOptions);
      setLoading(false);
    },
    [orgProduct, deselectedOptions, loading],
  );

  const updateSelection = useCallback(
    (selected: string[]) => {
      const nextSelectedTags = new Set([...selectedOptions]);
      if (nextSelectedTags.has(selected[0])) {
        nextSelectedTags.delete(selected[0]);
      } else {
        nextSelectedTags.add(selected[0]);
      }
      const selectedArray = Array.from(nextSelectedTags);
      setSelectedOptions(selectedArray);
      setOptions(deselectedOptions);
      setReportId(selected[0]);

      // Fetch the corresponding report name
      const report_name = deselectedOptions.find((option) => option.value === selected[0]);
      setReportName(report_name?.label ? report_name.label : null);

      let tmp = reports;

      if (selectedArray.length > 0) {
        const report = { _id: selected[0], name: report_name?.label ? report_name.label : '' };
        tmp.push(report);
      } else {
        tmp = [];
      }

      setReports(tmp);
      setInputValue('');
    },
    [options, selectedOptions],
  );

  const removeTag = useCallback(
    (tag: string) => {
      updateSelection([tag]);
    },
    [updateSelection],
  );

  const handleClearButtonClick = useCallback(() => {
    setOptions(deselectedOptions);
    // if (orgProduct?.reports && orgProduct?.report.name) {
    //   setIsEditing(true);
    // }
    if (orgProduct?.reports) {
      setIsEditing(true);
    }
    setOrgProduct({ ...orgProduct, report: null });
    setInputValue('');
    setReportId(null);
  }, [orgProduct]);

  const verticalContentMarkup =
    selectedOptions.length > 0 ? (
      <LegacyStack spacing="extraTight" alignment="center">
        {selectedOptions.map((tag) => {
          const matchedOption = deselectedOptions.find((option) => {
            return option.value.match(tag);
          });
          return matchedOption ? (
            <Tag key={`option-${tag}`} onRemove={() => removeTag(tag)}>
              {matchedOption.label}
            </Tag>
          ) : null;
        })}
      </LegacyStack>
    ) : null;

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label="Report"
      // value={orgProduct?.report && inputValue === '' ? orgProduct.report.name : inputValue}
      // value={!isEditing && orgProduct?.report && inputValue === '' ? orgProduct.report.name : inputValue}
      value={!isEditing && orgProduct?.reports && orgProduct.reports.length > 0 && inputValue === '' ? orgProduct.reports[0].name : inputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca il report"
      clearButton
      onClearButtonClick={handleClearButtonClick}
      verticalContent={verticalContentMarkup}
      autoComplete="off"
    />
  );

  const emptyState = (
    <React.Fragment>
      <Icon source={SearchMinor as any} />
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Non sono stati trovati risultati</TextContainer>
      </div>
    </React.Fragment>
  );

  /**
   * Modal handlers
   */
  const handleModalClose = useCallback(() => {
    setModalStatus(false);
  }, [setModalStatus]);

  const handleAdd = useCallback(() => {
    setButtonSpinning(true);

    if (!orgProduct?.value || isNaN(orgProduct?.value) || orgProduct?.value === 0) {
      setEmptyFields({ value: true });
      setButtonSpinning(false);
      return;
    }

    let tmp = null;
    console.log(orgProduct.reports, reports);
    if (orgProduct.reports && reports.length === 0) {
      console.log(orgProduct.reports, reportId);
      tmp = orgProduct.reports.map((report: any) => {
        return { _id: report._id, name: report.name };
      });
    } else if ((!orgProduct.reports || orgProduct.reports.length === 0) && selectedOptions.length > 0 && reports.length > 0) {
      tmp = reports;
    }

    saveOrgProduct(orgProduct, tmp);
    setReportId(null);
    setInputValue('');
    handleModalClose();
    setButtonSpinning(false);
  }, [handleModalClose, orgProduct, reports, reportId, reportName, selectedOptions]);

  const handleRemove = useCallback(() => {
    setOrgProduct(null);
    setReportId(null);
    setInputValue('');
    removeOrgProduct(orgProduct);
    setSelectedOptions([]);

    handleModalClose();
  }, [handleModalClose]);

  /**
   * Markup
   */
  const secondaryActions = useMemo(
    () => [
      {
        content: 'Cancella',
        onAction: handleRemove,
      },
    ],
    [handleRemove],
  );

  const footerMarkup = !isNew ? (
    <Button destructive onClick={handleRemove} loading={buttonSpinning}>
      Disattiva prodotto
    </Button>
  ) : undefined;

  return (
    <Modal
      open={modalStatus}
      onClose={handleModalClose}
      title={isNew ? 'Aggiungi commissione' : 'Modifica commissione'}
      primaryAction={{
        content: isNew ? 'Attiva prodotto' : 'Modifica prodotto',
        onAction: handleAdd,
        loading: buttonSpinning,
      }}
      secondaryActions={isNew ? secondaryActions : undefined}
      footer={footerMarkup}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <Select label="Tipologia" options={valueOptions} onChange={handleValueTypeChange} value={orgProduct?.value_type} />
            {orgProduct?.value_type === 'percentage' ? (
              <TextField
                label="Valore"
                autoComplete="off"
                value={orgProduct.value.toString()}
                onChange={handleValueChange}
                type="number"
                min={0}
                step={0.01}
                suffix="%"
                error={emptyFields.value && 'Il valore non può essere 0'}
              />
            ) : (
              <TextField
                label="Valore"
                autoComplete="off"
                value={orgProduct?.value.toString()}
                onChange={handleValueChange}
                type="number"
                min={0}
                step={0.01}
                prefix="EUR"
                suffix="€"
                error={emptyFields.value && 'Il valore non può essere 0'}
              />
            )}
          </FormLayout.Group>
          <FormLayout.Group>
            <Combobox
              allowMultiple
              activator={
                <Autocomplete
                  options={options}
                  selected={selectedOptions}
                  onSelect={updateSelection}
                  loading={loading}
                  textField={textField}
                  emptyState={emptyState}
                />
              }
            />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
