import { useCallback, useEffect, useRef, useState } from 'react';

import {
  LegacyCard,
  Frame,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  Toast,
  Banner,
  Text,
  LegacyStack,
  Badge,
  EmptyState,
  DataTable,
} from '@shopify/polaris';

import axios from 'axios';

import { TopBarMarkup, NavigationMarkup } from '../../../components';

import { Customer, IRiskIndividual } from '../../../types';

import dayjs from 'dayjs';
import it from 'dayjs/locale/it';
import { useParams } from 'react-router-dom';
import { useUser } from '../../../utils/PrivateRoute';

export function ReportDetailsFamily() {
  const params = useParams();
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const [saveError, setSaveError] = useState(false);
  const [amountError, setAmountError] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  /**
   * Report states
   */
  const [report, setReport] = useState<IRiskIndividual | undefined>();
  const [questions, setQuestions] = useState<string[][]>([]);
  const [results, setResults] = useState<string[][]>([]);
  const [customer, setCustomer] = useState<Customer | null>(null);

  /**
   * Create rows for the report
   */
  const createRowsArray = (report: IRiskIndividual) => {
    const questions = [];
    questions.push(['Età', report.age]);
    questions.push(['Attività lavorativa', report.work_activity]);
    questions.push(['Possiede negozi', report.shops]);
    questions.push(['Dipendenti', report.employees]);
    questions.push(['Figli', report.children]);
    questions.push(['Possiede terreni', report.lands]);
    questions.push(['Veicoli', report.vehicles]);
    questions.push(['Viaggi annuali', report.flights]);

    // Get the results
    const results: string[][] = [];
    const tmp: { label: string; type: string }[] = JSON.parse(report.response);
    for (const item of tmp) {
      results.push([item.type, item.label]);
    }
    console.log(results);

    return { questions, results };
  };

  /**
   * Data fetching:
   * - fetch report
   */
  useEffect(() => {
    const fetchReport = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/admin/risks/family/${params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setCustomer(data.data.user.customer);
          setReport(data.data);
          const { questions, results } = createRowsArray(data.data);
          setQuestions(questions);
          setResults(results);
        }
      } catch (error) {
        console.log(error);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReport();
  }, []);

  /**
   * Customer markup
   *
   * Display autocomplete if customer is not selected
   * When customer is loaded, display customer details
   */
  const customerMarkup = customer ? (
    <LegacyCard>
      <LegacyCard.Header title="Cliente" />
      <LegacyCard.Section>
        {report?.user.lastname ? (
          <Text as="p" fontWeight="semibold">
            {report?.user.lastname} {report?.user.name}
          </Text>
        ) : (
          <Text as="p" fontWeight="semibold">
            {report?.user.name}
          </Text>
        )}
        {report?.user?.email ? (
          <p>{report.user.email}</p>
        ) : (
          <p>
            <Badge>Mancante</Badge>
          </p>
        )}
      </LegacyCard.Section>
      <LegacyCard.Section title="Telefono">
        <LegacyCard.Subsection>
          <LegacyStack vertical>{report?.user?.phone ? <p>{report?.user.phone}</p> : <Badge>Mancante</Badge>}</LegacyStack>
        </LegacyCard.Subsection>
      </LegacyCard.Section>
      <LegacyCard.Section title="Indirizzo">
        <LegacyCard.Subsection>
          {customer?.address && report?.user.customer.address.line.length > 0 ? (
            <LegacyStack vertical spacing="none">
              <p>{report?.user.customer.address.line}</p>
              <p>
                {report?.user.customer.address.zip} {report?.user.customer.address.city}
              </p>
              <p>{report?.user.customer.address.country}</p>
            </LegacyStack>
          ) : (
            <p>
              <Badge>Mancante</Badge>
            </p>
          )}
        </LegacyCard.Subsection>
      </LegacyCard.Section>
    </LegacyCard>
  ) : null;

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="I dati sono stati aggiornati con successo." onDismiss={toggleActive} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const amountErrorMarkup = amountError && (
    <Layout.Section>
      <Banner title="L'importo inserito è maggiore di quanto dovuto" status="critical" onDismiss={() => setAmountError(false)}>
        <p>Si è pregati di controllare e riprovare.</p>
      </Banner>
    </Layout.Section>
  );

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page
      title={`${report?.user?.lastname ? report?.user.lastname + ' ' + report?.user.name : report?.user?.name} - ${dayjs(report?.date_created)
        .locale(it)
        .format('DD MMMM YYYY')}`}
      backAction={{ content: 'Reports', url: '/reports' }}
    >
      <Layout>
        {/* Banner */}
        {saveErrorMarkup}
        {amountErrorMarkup}

        <Layout.Section>
          {/* Report questions */}
          <LegacyCard title="Domande cliente">
            <DataTable columnContentTypes={['text', 'text']} headings={['Domanda', 'Valore']} rows={questions} />
          </LegacyCard>

          {/* Report answers */}
          <LegacyCard title="Risultato analisi">
            <DataTable columnContentTypes={['text', 'text']} headings={['Categoria', 'Valore']} rows={results} />
          </LegacyCard>
        </Layout.Section>

        <Layout.Section secondary>
          {/* Customer */}
          {customerMarkup}
        </Layout.Section>

        <Layout.Section></Layout.Section>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  // ---- Error ----
  const errorPageMarkup = (
    <Page>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <EmptyState
              heading="Nessun report presente a questo indirizzo"
              image="https://cdn.shopify.com/shopifycloud/web/assets/v1/08f1b23a19257042c52beca099d900b0.svg"
            >
              <p>Controlla l'URL e riprova oppure usa la barra di ricerca per trovare ciò di cui hai bisogno.</p>
            </EmptyState>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : error ? errorPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {pageMarkup}
      {toastMarkup}
    </Frame>
  );
}
