import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Button,
  LegacyCard,
  Frame,
  Layout,
  Loading,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  Modal,
  FormLayout,
  TextField,
  LegacyStack,
  Thumbnail,
  DropZone,
  Banner,
  Toast,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup, CompaniesList } from '../../../../components';

import './CompaniesAll.scss';
import { useUser } from '../../../../utils/PrivateRoute';
import { Company } from '../../../../types';
import { NoteMinor } from '@shopify/polaris-icons';
import axios, { AxiosError } from 'axios';

export function CompaniesAll() {
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  const toggleSuccess = useCallback(() => setSuccess((success) => !success), []);

  /** Loading markup */
  const loadingMarkup = isLoading ? <Loading /> : null;

  /** Company state */
  const [companies, setCompanies] = useState<Array<Company> | []>([]);
  const [company, setCompany] = useState<Company | null>(null);
  const [name, setName] = useState<string>(company?.name || '');
  const [logo, setLogo] = useState<File | null>(null);
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  // Name handler
  const handleCompanyNameChange = useCallback((value: string) => {
    setName(value);
  }, []);

  /**
   * Company modal
   */
  const [modalActive, setModalActive] = useState(false);
  const handleModalChange = useCallback(() => {
    setModalActive((modalActive) => !modalActive);
  }, []);

  /**
   * Fetch data
   */
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        setIsLoading(true);

        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/companies`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setCompanies(data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCompanies();
  }, [update]);

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setError(false);
      setButtonSpinning(true);

      // Create form data
      const formData = new FormData();
      if (logo) {
        formData.append('document', logo, logo?.name);
      }
      formData.append('name', name);

      // If company exists, update it
      let response, data;

      if (company) {
        response = await axios.put(
          (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/companies/${company ? company._id : ''}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          },
        );
        data = response.data;
      } else {
        response = await axios.post((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/companies/new`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        data = response.data;
      }

      if (data.status === 'success') {
        setSuccess(true);
        handleModalChange();
        setUpdate(!update);
      } else {
        setError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [company, name, logo]);

  /** Company drop zone logo */
  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
    setLogo(acceptedFiles[0]);
  }, []);

  const fileInDb = company && company.logo && (
    <div style={{ padding: '0' }}>
      <LegacyStack alignment="center">
        <Thumbnail size="large" alt={company.logo.title} source={process.env.REACT_APP_BLOB_IMAGES_URL + company.logo.key} />
        <div>{company.logo.title}</div>
      </LegacyStack>
    </div>
  );

  const fileUpload = !logo && company && company.logo ? fileInDb : !logo ? <DropZone.FileUpload /> : null;

  const uploadedFile = logo && (
    <div style={{ padding: '0' }}>
      <LegacyStack alignment="center">
        <Thumbnail size="large" alt={logo.name} source={validImageTypes.includes(logo.type) ? window.URL.createObjectURL(logo) : (NoteMinor as any)} />
        <div>{logo.name}</div>
      </LegacyStack>
    </div>
  );

  const logoDropZoneMarkup = (
    <DropZone onDrop={handleDropZoneDrop} allowMultiple={false} label="Logo">
      {uploadedFile}
      {fileUpload}
    </DropZone>
  );

  const modalMarkup = (
    <Modal
      open={modalActive}
      onClose={handleModalChange}
      title={company !== null ? 'Modifica compagnia' : 'Crea compagnia'}
      primaryAction={{
        content: company !== null ? 'Aggiorna' : 'Salva',
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handleModalChange,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextField label="Nome" value={name} onChange={handleCompanyNameChange} autoComplete="off" />
          {logoDropZoneMarkup}
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  /**
   * Banner & toast
   */
  const bannerMarkup = error && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const toastMarkup = success ? <Toast content="I dati sono stati aggiornati" onDismiss={toggleSuccess} /> : null;

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page
      fullWidth
      title="Compagnie"
      primaryAction={
        <Button primary onClick={handleModalChange}>
          Aggiungi compagnia
        </Button>
      }
    >
      <Layout>
        {/* Banner */}
        {bannerMarkup}

        <Layout.Section>
          <CompaniesList companies={companies} handleModalChange={handleModalChange} setCompany={setCompany} setCompanyName={setName} />
          {modalMarkup}
        </Layout.Section>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </Frame>
  );
}
