import { IndexTable, Button, useIndexResourceState, ButtonGroup, Badge } from '@shopify/polaris';
import React, { useCallback } from 'react';
import { OrganizationProduct } from '../../../../types';

interface Props {
  products: Array<any>;
  orgProducts: OrganizationProduct[];
  updateOrgProducts: any;
  handleCommissionModal: any;
}

function OrganizationProductList(props: Props) {
  const resourceIDResolver = (product: any) => {
    return product._id;
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(props.products, {
    resourceIDResolver,
  });

  const resourceName = {
    singular: 'prodotto',
    plural: 'prodotti',
  };

  const handleEdit = useCallback((productId: string, orgProduct: OrganizationProduct | undefined) => {
    props.handleCommissionModal(productId, orgProduct);
  }, []);

  /**
   * Render status badge
   * @param status boolean
   * @returns markup
   */
  const renderStatusMarkup = (status: boolean) => {
    return status ? (
      <Badge status="success" progress="complete">
        Attivo
      </Badge>
    ) : (
      <Badge progress="incomplete">Disattivo</Badge>
    );
  };

  // Concatenate the reports with a comma and render them
  const renderReportMarkup = (reports: any[]) => {
    return reports.map((report) => report.name).join(', ');
  };
  const rowMarkup = props.products.map(({ _id: id, name }, index) => {
    // If orgProducts is empty, it means that the organization has no products
    // and we can show all the products
    // Otherwise, we have to check if the product is in the orgProducts array
    // and if it is, we can show it
    let orgProduct: OrganizationProduct | undefined = undefined;
    let status = false;
    let valueType = <>&mdash;</>;
    let valueTypeSymbol = '€';
    let value = '0,00';
    let report = <>&mdash;</>;

    if (props.orgProducts.length > 0) {
      orgProduct = props.orgProducts.find((item) => item.product === id);
      if (orgProduct) {
        status = orgProduct.active;
        valueType = orgProduct.value_type === 'fixed_amount' ? <>Importo fisso</> : <>Percentuale</>;
        valueTypeSymbol = orgProduct.value_type === 'fixed_amount' ? '€' : '%';
        value = orgProduct.value ? orgProduct.value.toFixed(2).replace('.', ',') : '0,00';
        report = orgProduct.reports ? <>{renderReportMarkup(orgProduct.reports)}</> : <>&mdash;</>;
      }
    }

    return (
      <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
        <IndexTable.Cell>{name}</IndexTable.Cell>
        <IndexTable.Cell>{renderStatusMarkup(status)}</IndexTable.Cell>
        <IndexTable.Cell>{valueType}</IndexTable.Cell>
        <IndexTable.Cell>
          {value} {valueTypeSymbol}
        </IndexTable.Cell>
        <IndexTable.Cell>{report}</IndexTable.Cell>
        <IndexTable.Cell>
          <div style={{ float: 'right' }}>
            <ButtonGroup segmented>
              <Button size="slim" onClick={() => handleEdit(id, orgProduct)}>
                Modifica
              </Button>
            </ButtonGroup>
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  return (
    <IndexTable
      resourceName={resourceName}
      itemCount={props.products.length}
      selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
      onSelectionChange={handleSelectionChange}
      headings={[{ title: 'Nome' }, { title: 'Stato' }, { title: 'Tipologia provvigione' }, { title: 'Valore provvigione' }, { title: 'Report' }]}
    >
      {rowMarkup}
    </IndexTable>
  );
}

export { OrganizationProductList };
