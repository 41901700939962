import { Report } from './report';

// Address type
export type OrganizationAddress = {
  line: string;
  city: string;
  province: string;
  zip: string;
  country: string;
};

// Organization user type
export type OrganizationUser = {
  user: any;
  role: 'admin' | 'user';
};

export enum OrganizationRoles {
  admin = 'admin',
  user = 'user',
}

// Organization products type
export type OrganizationProduct = {
  product: any;
  active: boolean;
  value: number;
  value_type: 'fixed_amount' | 'percentage';
  reports?: any;
};

// Organization template type
export type OrganizationTemplate = {
  _id: string;
  /** File name: used to display in the UI */
  name: string;
  /** File key: used to fetch from object storage */
  key: string;
  /** File type: used to determine the file type */
  type: 'quote' | 'receipt';
  /** The products that should use this template */
  products?: any[];
};

// Organization file type
type OrganizationFile = {
  /** The file name stored */
  key: string;
  /** The file original name */
  title: string;
};

/**
 * Organization type
 */
export type Organization = {
  _id: string;
  name: string;
  email: string;
  phone?: string;
  address?: OrganizationAddress;
  vat: string;
  referral_code?: string;
  users?: OrganizationUser[];
  products?: OrganizationProduct[];
  /** The organization name used in the order sequential number */
  order_sequential_name?: string;
  /** The organization logo */
  logo?: OrganizationFile;
  /** Templates */
  templates?: OrganizationTemplate[];
  /** The analysis wallet available for the organization */
  wallet?: {
    family_analysis: number;
    family_unlimited: boolean;
  };
  /** The main organization (NANO) */
  main_organization?: boolean;
  /** The organization reports transactions */
  reports_transactions?: any;
  /** System information */
  date_created: Date;
  date_updated: Date;
};
