import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Autocomplete,
  Banner,
  LegacyCard,
  ContextualSaveBar,
  DropZone,
  FormLayout,
  Frame,
  Icon,
  Layout,
  LegacyStack,
  Page,
  TextField,
  Thumbnail,
  Toast,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup, contextControlMarkup } from '../../../../components';

import './OrganizationNew.scss';
import { CirclePlusMinor, NoteMinor, SearchMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import { useUser } from '../../../../utils/PrivateRoute';
import { useNavigate } from 'react-router-dom';

type AutocompleteUserOption = {
  label: string;
  value: string;
};

export function OrganizationNew() {
  const navigate = useNavigate();
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [active, setActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [exist, setExist] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  /**
   * States
   */
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [vat, setVat] = useState('');
  const [fiscalData, setFiscalData] = useState('');
  const [orderSequentialName, setOrderSequentialName] = useState('');

  const [line, setLine] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('IT'); // Value is country.code
  const [province, setProvince] = useState('');

  // Logo
  const [logo, setLogo] = useState<File>();
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  const [defaultState, setDefaultState] = useState({
    name: '',
    email: '',
    phone: '',
    vat: '',
    fiscalData: '',
    line: '',
    city: '',
    zip: '',
    country: 'IT',
    province: '',
  });

  /**
   * Empty fields
   */
  const [emptyFields, setEmptyFields] = useState({
    name: false,
    email: false,
    vat: false,
  });

  /**
   * Search organization admin
   */
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [deselectedOptions, setDeselectedOptions] = useState<AutocompleteUserOption[]>([]);
  const [options, setOptions] = useState<AutocompleteUserOption[]>([]);
  const [userId, setUserId] = useState('');

  const handleUserIdChange = useCallback((e: string) => {
    setUserId(e);
  }, []);

  /**
   * Autocomplete Controls
   */
  const updateText = useCallback(
    (value: any) => {
      setInputValue(value);

      if (value === '') {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedOptions.filter((option) => {
        // @ts-ignore
        return option.label.match(filterRegex);
      });
      setOptions(resultOptions);
    },
    [deselectedOptions],
  );

  const updateSelection = useCallback(
    (selected: any) => {
      const selectedValue = selected.map((selectedItem: any) => {
        const matchedOption = options.find((option) => {
          // @ts-ignore
          return option.value.match(selectedItem);
        });
        // @ts-ignore
        return matchedOption;
      });
      setSelectedOptions(selected);
      setInputValue(selectedValue[0].label);
      handleUserIdChange(selectedValue[0].value);
    },
    [handleUserIdChange, options],
  );

  const customerTextField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateText}
      label="Ricerca utente"
      value={inputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca"
    />
  );

  /** Discard */
  const handleDiscard = useCallback(() => {
    setName(defaultState.name);
    setEmail(defaultState.email);
    setPhone(defaultState.phone);
    setVat(defaultState.vat);
    setFiscalData(defaultState.fiscalData);
    setLine(defaultState.line);
    setCity(defaultState.city);
    setZip(defaultState.zip);
    setCountry(defaultState.country);
    setProvince(defaultState.province);
    setLogo(undefined);
    setIsDirty(false);
  }, [defaultState]);

  /**
   * Data fetching:
   * - fetch users
   */
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/users/?role=partner', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const tmp = [];
          for (const user of data.data) {
            tmp.push({
              value: user._id,
              label: user.lastname && user.lastname.length > 0 ? `${user.name} ${user.lastname} - ${user.email}` : `${user.name} - ${user.email}`,
            });
          }
          setDeselectedOptions(tmp);
          setOptions(tmp);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsers();
  }, []);

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);
      let flag = false;

      if (name === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: true }));
        flag = true;
      }
      if (email === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, email: true }));
        flag = true;
      }
      if (vat === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, vat: true }));
        flag = true;
      }

      if (flag) {
        setButtonSpinning(false);
        return;
      }

      // Create form data
      const formData = new FormData();
      if (logo) {
        formData.append('document', logo, logo?.name);
      }
      formData.append('name', name);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('vat', vat);
      formData.append('fiscal_data', fiscalData);
      formData.append('order_sequential_name', orderSequentialName);
      if (userId) {
        formData.append('users', JSON.stringify([{ user: userId, role: 'admin' }]));
      }
      formData.append('line', line);
      formData.append('city', city);
      formData.append('zip', zip);

      const response = await axios.post((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/organizations/new', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;

      if (data.status === 'success') {
        setActive(true);
        setTimeout(() => {
          navigate(`/admin/organizations/${data.data._id}`);
        }, 3000);
      } else if (data.status === 'organitazion_exists') {
        setExist(true);
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setButtonSpinning(false);
    }
    setIsDirty(false);
  }, [name, email, phone, userId, vat, fiscalData, orderSequentialName, logo, line, city, zip, history]);

  /**
   * Handlers
   */
  const handleNameChange = useCallback(
    (e: string) => {
      if (emptyFields.name) {
        setEmptyFields({ ...emptyFields, name: false });
      }

      setName(e);
      setIsDirty(true);
    },
    [emptyFields.name],
  );

  // Email
  const handleEmailChange = useCallback(
    (e: string) => {
      if (emptyFields.email) {
        setEmptyFields({ ...emptyFields, email: false });
      }

      setEmail(e);
      setIsDirty(true);
    },
    [emptyFields.email],
  );

  // Vat
  const handleVatChange = useCallback(
    (e: string) => {
      if (emptyFields.vat) {
        setEmptyFields({ ...emptyFields, vat: false });
      }

      setVat(e);
      setIsDirty(true);
    },
    [emptyFields.vat],
  );

  // Fiscal data
  const handleFiscalDataChange = useCallback((e: string) => {
    setFiscalData(e);
    setIsDirty(true);
  }, []);

  // Order sequential name
  const handleOrderSequentialNameChange = useCallback((e: string) => {
    setOrderSequentialName(e);
    setIsDirty(true);
  }, []);

  // Phone
  const handlePhoneChange = useCallback((e: string) => {
    // Allow only numbers
    if (e.match(/^[0-9]*$/)) {
      setPhone(e);
    }
    setIsDirty(true);
  }, []);

  // Line
  const handleLineChange = useCallback((e: string) => {
    setLine(e);
  }, []);

  // City
  const handleCityChange = useCallback((e: string) => {
    setCity(e);
  }, []);

  // Zip
  const handleZipChange = useCallback((e: string) => {
    // Allow only numbers
    if (e.match(/^[0-9]*$/)) {
      setZip(e);
    }

    // Allow only 5 numbers
    if (e.length > 5) {
      setZip(e.substring(0, 5));
    }
  }, []);

  // Country
  const handleCountryChange = useCallback((e: string) => {
    setCountry(e);
  }, []);

  /**
   * Organization drop zone logo
   */
  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
    setLogo(acceptedFiles[0]);
    setIsDirty(true);
  }, []);

  const fileUpload = !logo && <DropZone.FileUpload />;

  const uploadedFile = logo && (
    <div style={{ padding: '0' }}>
      <LegacyStack alignment="center">
        <Thumbnail size="large" alt={logo.name} source={validImageTypes.includes(logo.type) ? window.URL.createObjectURL(logo) : (NoteMinor as any)} />
        <div>{logo.name}</div>
      </LegacyStack>
    </div>
  );

  const logoDropZoneMarkup = (
    <DropZone onDrop={handleDropZoneDrop} allowMultiple={false}>
      {uploadedFile}
      {fileUpload}
    </DropZone>
  );

  /**
   * Save bar
   */
  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Organizzazione non salvata"
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
      contextControl={contextControlMarkup}
    />
  ) : null;

  /**
   * Markups & error
   */
  const saveError = error && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const existError = exist && (
    <Layout.Section>
      <Banner title="Esiste già un utente associato a questo indirizzo email" status="critical" onDismiss={() => setExist(false)}>
        <p>Si è pregati di cambiare l'email utente se si desidera proseguire.</p>
      </Banner>
    </Layout.Section>
  );

  const toastMarkup = active ? <Toast content="L'organizzazione è stata creata con successo." onDismiss={toggleActive} /> : null;

  /**
   * Remove logo action
   */
  const handleModalRemoveLogoChange = useCallback(() => {
    setLogo(undefined);
  }, []);

  const removeLogoAction = logo
    ? [
        {
          content: 'Rimuovi logo',
          onAction: handleModalRemoveLogoChange,
        },
      ]
    : undefined;

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page title={`Nuova Organizzazione`} backAction={{ content: 'Organizzazioni', url: '/admin/organizations' }}>
      <Layout>
        {/* Banner */}
        {saveError}
        {existError}

        {/* Panoramica organizzazione */}
        <Layout.AnnotatedSection
          title="Dettagli organizzazione"
          description="Un'organizzazione rappresenta un partner (broker, agenzia, azienda, altro) che ha un accordo di collaborazione."
        >
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="text"
                  label="Nome"
                  value={name}
                  onChange={handleNameChange}
                  error={emptyFields.name && 'Il campo è obbligatorio'}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="text"
                  label="P.IVA"
                  value={vat}
                  onChange={handleVatChange}
                  error={emptyFields.vat && 'Il campo è obbligatorio'}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="email"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                  helpText="Verrà utilizzato per le comunicazioni all'azienda."
                  error={emptyFields.email && 'Il campo è obbligatorio'}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="tel"
                  label="Numero di telefono"
                  value={phone}
                  onChange={handlePhoneChange}
                  helpText="Verrà utilizzato per le comunicazioni all'azienda."
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>

        {/* Fiscal Data */}
        <Layout.AnnotatedSection
          title="Dati fiscali"
          description="Le informazioni fiscali che dovranno essere stampate nei preventivi e nelle fatture delle polizze collettive."
        >
          <LegacyCard sectioned>
            <TextField label="Dati fiscali" value={fiscalData} onChange={handleFiscalDataChange} multiline={4} autoComplete="off" />
          </LegacyCard>
        </Layout.AnnotatedSection>

        {/* Logo */}
        <Layout.AnnotatedSection title="Logo" description="Il logo dell'organizzazione. Caricare una nuova immagine per sostituire quella attuale.">
          <LegacyCard sectioned actions={removeLogoAction}>
            {logoDropZoneMarkup}
          </LegacyCard>
        </Layout.AnnotatedSection>

        {/* Order sequential name */}
        <Layout.AnnotatedSection
          title="Progressivo ordini"
          description="Il codice progressivo, relativo all'organizzazione, che sarà utilizzato per identificare i preventivi e le fatture."
        >
          <LegacyCard sectioned>
            <TextField label="Progressivo" value={orderSequentialName} onChange={handleOrderSequentialNameChange} autoComplete="off" />
          </LegacyCard>
        </Layout.AnnotatedSection>

        {/* Address */}
        <Layout.AnnotatedSection title="Indirizzo" description="L'indirizzo principale di questa organizzazione.">
          <LegacyCard sectioned>
            <FormLayout>
              {/* <FormLayout.Group>
                <Select label="Stato" options={countryOptions} onChange={handleCountryChange} value={country} />
              </FormLayout.Group> */}
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="Indirizzo" value={line} onChange={handleLineChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="CAP" value={zip} onChange={handleZipChange} />
                <TextField autoComplete="on" type="text" label="Città" value={city} onChange={handleCityChange} />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Admin user */}
        <Layout.AnnotatedSection title="Account amministratore">
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Autocomplete
                  options={options}
                  selected={selectedOptions}
                  onSelect={updateSelection}
                  textField={customerTextField}
                  loading={isLoading}
                  actionBefore={{
                    accessibilityLabel: 'Action label',
                    content: 'Aggiungi utente',
                    ellipsis: true,
                    icon: CirclePlusMinor as any,
                    onAction: () => {
                      navigate('/admin/users/new');
                    },
                  }}
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );

  const pageMarkup = actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {pageMarkup}
      {toastMarkup}
    </Frame>
  );
}
