import { Pagination, LegacyCard, EmptyState, Filters, ResourceItem, ResourceList, Text, Thumbnail, HorizontalStack, LegacyFilters } from '@shopify/polaris';
import React, { useCallback, useState, useEffect } from 'react';

import styles from './CompaniesList.module.css';
import { Company } from '../../types';

type Props = {
  companies: Array<Company> | [];
  handleModalChange: () => void;
  setCompany: (company: Company) => void;
  setCompanyName: (name: string) => void;
};

export function CompaniesList({ companies, handleModalChange, setCompany, setCompanyName }: Props) {
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [taggedWith, setTaggedWith] = useState<any>('VIP');
  const [queryValue, setQueryValue] = useState('');
  const [sortValue, setSortValue] = useState('NAME_ASC');
  const [items, setItems] = useState(companies);
  const [frontItems, setFrontItems] = useState(companies);
  const [totalItems, setTotalItems] = useState(companies.length || 0);
  const [isLoading, setIsLoading] = useState(false);

  // Use page number to set current group of companies
  const [pageNumber, setPageNumber] = useState(1);

  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTaggedWithRemove]);

  const resourceName = {
    singular: 'compagnia',
    plural: ' compagnie',
  };

  const filters: any = [];

  // Filtering
  useEffect(() => {
    const filterItems = () => {
      const filteredItems = items.filter((item: Company) => {
        return item.name.toLowerCase().includes(queryValue.toLowerCase());
      });
      setFrontItems(filteredItems);
    };
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  const handleQueryChange = useCallback((e: string) => {
    setQueryValue(e);
  }, []);

  const filterControl = (
    <LegacyFilters
      queryValue={queryValue}
      filters={filters}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleClearAll}
    ></LegacyFilters>
  );

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'NAME_DESC') {
      const tmp = [...items];
      // @ts-ignore
      tmp.sort((a, b) => (b.company_name > a.company_name ? 1 : a.company_name > b.company_name ? -1 : 0));
      setItems(tmp);
      setFrontItems(tmp.splice(0, 50));
    } else if (sortValue === 'NAME_ASC') {
      const tmp = [...items];
      // @ts-ignore
      tmp.sort((a, b) => (a.company_name > b.company_name ? 1 : b.company_name > a.company_name ? -1 : 0));
      setItems(tmp);
      setFrontItems(tmp.splice(0, 50));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  const onSelect = useCallback((company: Company) => {
    setCompany(company);
    setCompanyName(company.name);
    handleModalChange();
  }, []);

  /**
   * Render item
   */
  function renderItem(item: Company, index: string) {
    const media =
      item.logo && item.logo.title && item.logo.key ? (
        <Thumbnail size="medium" alt={item.logo.title} source={process.env.REACT_APP_BLOB_IMAGES_URL + item.logo.key} />
      ) : undefined;

    return (
      <ResourceItem id={index} onClick={() => onSelect(item)} sortOrder={Number(index)} accessibilityLabel={`View details for ${name}`}>
        <HorizontalStack blockAlign="center" gap={'5'}>
          {media}
          <Text as="span" fontWeight="semibold">
            {item.name}
          </Text>
        </HorizontalStack>
      </ResourceItem>
    );
  }

  function resolveItemIds({ id }: any) {
    return id;
  }

  const emptyStateMarkup = !items.length ? (
    <LegacyCard>
      <LegacyCard.Section>
        <EmptyState heading="Gestisci le compagnie" image="https://cdn.shopify.com/shopifycloud/web/assets/v1/e7b58a8b2e612fe6cf6f8c9e53830b70.svg">
          <p>Qua è dove puoi gestire le compagnie</p>
        </EmptyState>
      </LegacyCard.Section>
    </LegacyCard>
  ) : undefined;

  /**
   * Pagination
   */
  const handlePreviousPage = useCallback(() => {
    setFrontItems(items.splice((pageNumber - 1) * 50, pageNumber * 50));
    setPageNumber(pageNumber - 1);
  }, [items, pageNumber]);
  const handleNextPage = useCallback(() => {
    setFrontItems(items.splice(pageNumber * 50, (pageNumber + 1) * 50));
    setPageNumber(pageNumber + 1);
  }, [items, pageNumber]);

  const paginationMarkup =
    items.length > 0 ? (
      <div className={styles.Pagination}>
        <Pagination
          hasPrevious={queryValue === null && pageNumber !== 1}
          hasNext={queryValue === null && pageNumber !== totalItems / 50}
          onPrevious={handlePreviousPage}
          onNext={handleNextPage}
        />
      </div>
    ) : null;

  /**
   * Markup with items
   */
  const companyListMarkup = (
    <LegacyCard>
      <ResourceList
        emptyState={emptyStateMarkup}
        resourceName={resourceName}
        items={frontItems}
        renderItem={renderItem}
        selectedItems={selectedItems}
        onSelectionChange={setSelectedItems}
        resolveItemId={resolveItemIds}
        filterControl={filterControl}
        sortValue={sortValue}
        sortOptions={[
          { label: 'Nome decrescente', value: 'NAME_DESC' },
          { label: 'Nome crescente', value: 'NAME_ASC' },
        ]}
        onSortChange={(selected) => {
          setSortValue(selected);
        }}
        totalItemsCount={totalItems}
        loading={isLoading}
      />
      {paginationMarkup}
    </LegacyCard>
  );

  return companyListMarkup;
}
