export enum UserRoles {
  admin = 'admin',
  manager = 'manager',
  analyst = 'analyst',
  sales = 'sales',
  customer = 'customer',
  partner = 'partner',
}

export type User = {
  _id: string;
  email: string;
  name: string;
  lastname?: string;
  phone?: string;
  privacy?: boolean;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  customer?: string;
  date_created: Date;
  date_updated: Date;
  role: string;
  verified: boolean;
};
