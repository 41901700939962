import {
  LegacyCard,
  EmptyState,
  Badge,
  Filters,
  Text,
  IndexTable,
  Link,
  Select,
  useIndexResourceState,
  FilterInterface,
  ComplexAction,
} from '@shopify/polaris';
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export function ProductList({ setActive, setSaveError, setCounterError, user }: any) {
  const navigate = useNavigate();

  const [queryValue, setQueryValue] = useState('');
  const [taggedWith, setTaggedWith] = useState(null);
  const [sortValue, setSortValue] = useState('DATE_CREATED_DESC');
  const [items, setItems] = useState([]);
  const [frontItems, setFrontItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const resourceName = {
    singular: 'prodotto',
    plural: 'prodotti',
  };

  type Product = {
    _id: string;
    name: string;
    description: string;
    variants: any[];
    price: string;
    company: string;
    category: any;
    status: string;
    date_created: string;
    date_updated: string;
  };

  /**
   * Data fetching
   */
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/products', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setItems(data.data);
          setFrontItems(data.data);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchProducts().then(() => setIsLoading(false));
  }, [update, user.id]);

  /**
   * Handle search query
   */
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  /**
   * Filters
   */
  function disambiguateLabel(key: string, value: null) {
    switch (key) {
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value: string | any[] | null) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const filters: FilterInterface[] = [];

  const appliedFilters = !isEmpty(taggedWith)
    ? [
        {
          key: 'taggedWith',
          label: disambiguateLabel('taggedWith', taggedWith),
          // @ts-ignore
          onRemove: handleTaggedWithRemove,
        },
      ]
    : [];

  // Filtering
  useEffect(() => {
    const filterItems = () => {
      const filteredItems = items.filter((item: Product) => {
        return item.name.toLowerCase().includes(queryValue ? queryValue.toLowerCase() : '');
      });
      setFrontItems(filteredItems);
    };
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'DATE_CREATED_ASC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CREATED_DESC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
      setFrontItems(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  /**
   * Index table handlers & markup
   */
  const resourceIDResolver = (products: { _id: any }) => {
    return products._id;
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(frontItems, {
    resourceIDResolver,
  });

  // Delete selected notes
  const handleDelete = useCallback(async () => {
    try {
      const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/products`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          products: selectedResources,
        }),
      });
      const response = await data.json();

      if (response.status === 'success' && response.data) {
        setCounterError(true);
        setUpdate(!update);
      } else if (response.status === 'success') {
        setActive(true);
        setUpdate(!update);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      console.log(error);
      setSaveError(true);
    }
  }, [selectedResources, setActive, setCounterError, setSaveError, update]);

  const promotedBulkActions = [
    {
      content: 'Elimina polizze',
      onAction: handleDelete,
    },
  ];

  //
  const rowMarkup = frontItems.map((item: Product, index) => {
    const { _id: id, name, variants, category, status } = item;

    let statusMarkup;
    if (status === 'active') {
      statusMarkup = (
        <Badge status="success" progress="complete">
          Attivo
        </Badge>
      );
    } else if (status === 'draft') {
      statusMarkup = <Badge progress="incomplete">Bozza</Badge>;
    }

    return (
      <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
        <IndexTable.Cell>
          <Text as="span" fontWeight="semibold">
            <Link url={`/admin/products/${id}`} removeUnderline monochrome dataPrimaryLink>
              {name}
            </Link>
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{statusMarkup}</IndexTable.Cell>
        <IndexTable.Cell>{category.name}</IndexTable.Cell>
        <IndexTable.Cell>{variants.length}</IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  /**
   * Empty list markup
   */
  let emptyStateMarkup: ComplexAction | undefined = undefined;
  if (user.role === 'admin') {
    emptyStateMarkup = {};
    emptyStateMarkup.content = 'Aggiungi un prodotto';
    emptyStateMarkup.onAction = () => {
      navigate('/admin/products/new');
    };
  }

  const emptyList = (
    <EmptyState
      heading="Gestisci i prodotti"
      image="https://cdn.shopify.com/shopifycloud/web/assets/v1/67d1bd2ad29c4adc50fb195375f31fcb1674823604794398778c01f6c185d702.svg"
      action={emptyStateMarkup}
    >
      <p>Qua è dove puoi gestire i tuoi prodotti</p>
    </EmptyState>
  );

  /**
   * Markup with items
   */
  useEffect(() => {
    if (items.length > 0) setIsLoading(false);
  }, [items]);

  const customerListMarkup = (
    <LegacyCard>
      <div style={{ padding: '16px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            filters={filters}
            // @ts-ignore
            appliedFilters={appliedFilters}
            queryPlaceholder={'Filtra prodotti'}
            onQueryChange={setQueryValue}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
          />
        </div>
        <div style={{ paddingLeft: '0.4rem' }}>
          <Select
            labelInline
            label="Ordina per"
            options={[
              { label: 'Data aggiunta prodotto (dal più recente)', value: 'DATE_CREATED_DESC' },
              { label: 'Data aggiunta prodotto (dal meno recente)', value: 'DATE_CREATED_ASC' },
            ]}
            value={sortValue}
            onChange={(selected) => {
              setSortValue(selected);
            }}
          />
        </div>
      </div>
      <IndexTable
        resourceName={resourceName}
        emptyState={emptyList}
        loading={isLoading}
        itemCount={frontItems.length}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        hasMoreItems
        promotedBulkActions={promotedBulkActions}
        onSelectionChange={handleSelectionChange}
        headings={[{ title: 'Nome' }, { title: 'Stato' }, { title: 'Categoria' }, { title: 'Varianti' }]}
      >
        {rowMarkup}
      </IndexTable>
    </LegacyCard>
  );

  return customerListMarkup;
}
